import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://55cd63e6ed4d4996b87be98649c2e00a@o80434.ingest.sentry.io/6193534',
  environment: process.env.GATSBY_PROD === 'true' ? 'prod' : 'staging',
  integrations: [new BrowserTracing()],
  release: process.env.GATSBY_PROD === 'true' ? process.env.COMMIT_REF : undefined,
  tracesSampleRate: process.env.GATSBY_PROD === 'true' ? 0.6 : 1.0
});
